import {AZURE_AD_CLIENT_ID, AZURE_AD_TENANT_ID, REDIRECT_URI} from '../config';
import * as msal from '@azure/msal-browser';

export class AuthConfig {
  static msalConfig: msal.Configuration = {
    auth: {
      clientId: AZURE_AD_CLIENT_ID,
      authority: `https://login.microsoftonline.com/${AZURE_AD_TENANT_ID}`,
      redirectUri: REDIRECT_URI,
      postLogoutRedirectUri: `${REDIRECT_URI}/login`,
    },
    cache: {
      cacheLocation: 'localStorage',
      storeAuthStateInCookie: false,
    },
    system: {
      loggerOptions: {
        loggerCallback: (level, message, containsPii) => {
          if (containsPii) {
            return;
          }
          switch (level) {
            case msal.LogLevel.Error:
              console.error(message);
              return;
            case msal.LogLevel.Info:
              console.info(message);
              return;
            case msal.LogLevel.Verbose:
              console.debug(message);
              return;
            case msal.LogLevel.Warning:
              console.warn(message);
              return;
          }
        },
      },
    },
  };

  static loginRequest = {
    scopes: ['User.Read'],
  };

  static tokenRequest = {
    scopes: ['User.Read', 'Mail.Read'],
    forceRefresh: false, // Set this to "true" to skip a cached token and go to the server to get a new token
  };
}
